import ApiService from '@/common/api.service'

const UserManageService = {

  generate() {
    const pLength = 6;
    const keyListAlpha = 'abcdefghijklmnopqrstuvwxyz';
    const upperKeyListAlpha = keyListAlpha.toUpperCase();
    const keyListInt = '123456789';
    const keyListSpec = '!@#_';
    let password = '';
    let len = Math.ceil(pLength / 2);
    len -= 1;
    const lenSpec = pLength - 2 * len;

    for (let i = 0; i < len; i += 1) {
      password += upperKeyListAlpha.charAt(Math.floor(Math.random() * upperKeyListAlpha.length));
      password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
      password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
    }

    for (let i = 0; i < lenSpec; i += 1) {
      password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length));
    }

    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    this.userData.password = password
  },

  sendEmail(userData, instance) {
    const {
      sAd,
      sSoyad,
      sEMail,
      sParola,
    } = userData
    const creds = {
      sAd,
      sSoyad,
      sEMail,
      sParola, 
    }
    return ApiService.post('contact/sendemail/usercred', creds, instance);
  },
}

export default UserManageService
